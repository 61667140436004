<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
      fill="#ACDEB1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.29304 11.2929C7.68357 10.9024 8.31673 10.9024 8.70726 11.2929L10.933 13.5186L15.2319 8.35982C15.5855
 7.93554 16.2161 7.87821 16.6403 8.23178C17.0646 8.58534 17.1219 9.21591 16.7684 9.64018L11.7684 15.6402C11.5885 15.856 11.3261 15.9862 11.0455
  15.999C10.7648 16.0117 10.4917 15.9058 10.293 15.7071L7.29304 12.7071C6.90252 12.3166 6.90252 11.6834 7.29304 11.2929Z"
      fill="#2C7E34"
    />
  </svg>
</template>
